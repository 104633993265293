import React, {useEffect} from "react";
import {useStore} from "../../state";
import deTranslations from "../../translations/de";
import enTranslations from "../../translations/en";
import products from "../../utils/products";
import {IoChevronBackOutline, IoHourglassOutline} from "react-icons/io5";
import {FaSquareFacebook} from "react-icons/fa6";
import {IoLogoInstagram} from "react-icons/io";
import {FaLinkedin} from "react-icons/fa";



export default function PickOption() {

    const [product, setProduct] = React.useState({name: ""});

    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;

    const increaseStep = useStore(state => state.increaseStep);
    const decreaseStep = useStore(state => state.decreaseStep);

    const option = useStore(state => state.option);
    const setOption = useStore(state => state.setOption);

    useEffect(() => {
        if(option){
            setProduct(option);
        }
    }, []);

    return (
        <>
            <div className={'flex mt-6'}>
                <div className={'flex cursor-pointer'} onClick={() => {
                    decreaseStep();
                }}>
                    <IoChevronBackOutline size={20} className={'text-3xl mt-1 cursor-pointer'}/>
                    <p>{t.backButton}</p>
                </div>
            </div>
            <div className={'mt-2 bg-grey px-4 pt-6 pb-2 rounded-t-2xl'}>
                <h2 className={'text-2xl font-bold'}>4. {t.pickOption}</h2>
            </div>
            <div className={'flex flex-col md:flex-row md:justify-around p-6 bg-grey'}>
                <div
                    className={`flex ${product.name === "Basic" ? "bg-flame" : ""} flex-col md:w-1/3 border-2 font-bold border-black rounded-2xl`}
                    onClick={() => {
                        setOption(products[0]);
                        setProduct(products[0]);
                    }}>
                    <p className={'text-xl text-center border-black border-b py-2'}>Beginner</p>
                    <div className={'flex px-3 space-x-3 py-2 border-black border-b'}>
                        <FaSquareFacebook className={''} size={25}/>
                        <p>Facebook</p>
                    </div>
                    <div className={'flex px-3 space-x-2 py-2 border-black border-b'}>
                        <IoLogoInstagram className={''} size={30}/>
                        <p className={"mt-0.5"}>Instagram</p>
                    </div>
                    <div className={'flex px-3 space-x-2 py-2'}>
                        <IoHourglassOutline className={''} size={30}/>
                        <p className={"mt-0.5"}>{t.timeOption}</p>
                    </div>
                    <div className={`flex px-3 ${product.name === "Basic" ? "text-flame" : "text-grey"} space-x-2 py-2`}>
                        <IoHourglassOutline className={''} size={30}/>
                        <p className={"mt-0.5"}>{t.timeOption}</p>
                    </div>
                </div>
                <div
                    className={`flex ${product.name === "Premium" ? "bg-flame" : ""} flex-col md:w-1/3 border-2 font-bold border-black rounded-2xl mt-8 md:mt-0`}
                    onClick={() => {
                        setOption(products[1]);
                        setProduct(products[1]);
                    }}>
                    <p className={'text-xl text-center border-black border-b py-2'}>Professional</p>
                    <div className={'flex px-3 space-x-3 py-2 border-black border-b'}>
                        <FaSquareFacebook className={''} size={25}/>
                        <p>Facebook</p>
                    </div>
                    <div className={'flex px-3 space-x-2 py-2 border-black border-b'}>
                        <IoLogoInstagram className={''} size={30}/>
                        <p className={"mt-0.5"}>Instagram</p>
                    </div>
                    <div className={'flex px-3 space-x-2 py-2 border-black border-b'}>
                        <FaLinkedin className={''} size={30}/>
                        <p className={"mt-0.5"}>LinkedIn</p>
                    </div>
                    <div className={'flex px-3 space-x-2 py-2'}>
                        <IoHourglassOutline className={''} size={30}/>
                        <p className={"mt-0.5"}>{t.timeOption}</p>
                    </div>
                </div>
            </div>
            <div className={'bg-grey h-6 rounded-b-2xl'}/>
            <div
                className={`flex select-none justify-center w-full md:w-2/5 mx-auto py-4 ${option ? 'bg-flame cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} mt-6 mb-12 font-bold text-xl rounded-xl`}
                onClick={option ? increaseStep : null}>
                <p>{t.continueButton}</p>
            </div>
        </>
    );
}