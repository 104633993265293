import ImageItem from "./ImageItem";
import React from "react";
import {useStore} from "../../state";
import deTranslations from "../../translations/de";
import enTranslations from "../../translations/en";


export default function ImagePicker() {
    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;

    const pickedImagePath = useStore(state => state.pickedImagePath);
    const setPickedImagePath = useStore(state => state.setPickedImagePath);

    const imagePaths = Array(11).fill(null).map((_, idx) => {
        const numString = (idx + 1).toString().padStart(2, '0');
        return `/images/post/${numString}.jpeg`;
    });

    const increaseStep = useStore(state => state.increaseStep);

    return (
        <>
            <div className={'mt-6 bg-grey px-4 pt-6 pb-2 rounded-t-2xl'}>
                <h2 className={'text-2xl font-bold'}>1. {t.pickAnImage}</h2>
            </div>
            <div className={'flex flex-col h-96 md:h-128 overflow-y-scroll scroll-y-auto p-6 bg-grey'}>
                <div className={'grid grid-cols-2 md:grid-cols-3 gap-6'}>
                    {imagePaths.map((path, idx) => (
                        <ImageItem
                            key={path}
                            src={path}
                            alt={`Post Image ${idx + 1}`}
                            isSelected={path === pickedImagePath}
                            onClick={() => setPickedImagePath(path)}
                        />
                    ))}
                </div>
            </div>
            <div className={'bg-grey h-6 rounded-b-2xl'}/>
            <div
                className={`flex select-none justify-center w-full md:w-2/5 mx-auto py-4 ${pickedImagePath ? 'bg-flame cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} mt-6 mb-12 font-bold text-xl rounded-xl`}
                onClick={pickedImagePath ? increaseStep : null}>
                <p>{t.continueButton}</p>
            </div>
        </>
    );
}