export default {
    "total": "Gesamt",
    "checkPromoCode": "Einlösen",
    "checkFirstname":"Vorname",
    "checkLastname":"Nachname",
    "checkEmail":"E-Mail",
    "checkPhone":"Telefonnummer",
    "checkCompany":"Firma",
    "checkStreet":"Straße",
    "checkStreetNumber":"Hausnummer",
    "checkZip":"Postleitzahl",
    "checkCity":"Stadt",

    "bookNow": "Jetzt buchen",
    "checkOut": "Buchen Sie Ihre Kampagne",
    "chosenOption": "Gewählte Option",
    "overviewTime": "Laufzeit",
    "overviewTargetGroup": "Zielgruppenbeschreibung",
    "yourCampaign": "Ihre Kampagne",
    "overview": "Übersicht",
    "timeOption": "3 Wochen",
    "pickOption": "Wählen Sie eine Option",
    "landingPageURL":"Geben Sie die URL Ihrer Landingpage an",
    "targetGroupDescriptionPlaceholder": "Zielgruppe",
    "targetGroupDescription": "Beschreiben Sie Ihre Zielgruppe.",
    "descriptionOfCampaign": "Beschreiben Sie Ihre Kampagne. Dieser Text wird unter Ihrem Post angezeigt.",
    "descriptionPlaceholder": "Beschreibung für Ihre Kampagne",
    "description": "Beschreibung",
    "additionalInfos": "Zusätzliche Informationen",
    "areaHelpText": "Dein Text",
    "typeInText": "Gib hier deinen Text an",
    "backButton": "zurück",
    "customizeImage": "Kampagne gestalten",
    "textColorButton": "Textfarbe",
    "backgroundColorButton": "Hintergrundfarbe",
    "continueButton": "Weiter",
    "pickAnImage": "Wähle ein Bild aus",
    "pageDescription": "Der einfachste Weg Ihre Geschäftsidee zu testen und erste Leads zu gewinnen.",
    "withoutPrototype": "Auch ohne Prototyp möglich",
    "reachThousands": "Tausende Interessenten ohne eigene Social-Media Präsenz erreichen",
    "saveTimeResources": "Zeit und Ressourcen schonen",
    "heroText": "Der einfachste Weg Ihre Geschäftsidee zu testen und erste Leads zu gewinnen.",
    "contact": "Kontakt",
    "dataProcessingContract": "Auftragsverarbeitungsvertrag",
    "aboutUs": "Über uns",
    "legalNotice": "Impressum",
    "privacy": "Datenschutz",
    "copyright": "© 2023 validyt",
    "pageTitle": "validyt.",
    "campaignStart": "Jetzt Kampagne Starten",
    "beforeInvestingTitle": "Noch bevor Sie in eine neue Geschäftsidee Zehntausende Euros und unzählige Stunden an Arbeit reinstecken, teste Sie Ihre Idee einfach und kostengünstig mit validyt.",
    "createCampaignTitle": "Kampagne erstellen",
    "createCampaignDescription": "Erstellen Sie Ihre Social-Media Kampagne in wenigen Minuten. Bestimmen Sie ihre Zielgruppe und entscheiden Sie über welche Kanäle Sie werben möchten.",
    "altSocialMedias": "Verschiedene Social-Media-Plattformen",
    "withoutValidytDescription": "Für eine erfolgreiche Kampagne bräuchten Sie ohne validyt ein professionelles Profil mit Inhalten und Followern, damit Ihr Angebot ernst genommen wird. Mit validyt nutzen Sie unser Werbeprofil und müssen kein eigenes erstellen.",
    "altProfile": "Profilbild",
    "collectLeadsTitle": "Leads sammeln",
    "collectLeadsDescription": "Anhand der Zahlen können Sie sich entscheiden, ob Sie die Geschäftsidee umsetzen wollen. Die Kennzahlen liefern Ihnen wertvolle Informationen für zukünftige Kampagnen.",
    "peopleReached": "Personen erreicht",
    "clicks": "Klicks",
    "leads": "Leads",
    "forFounders": "Für Gründer",
    "forCompanies": "Für Unternehmen",
    "startNow": "Jetzt starten",
    "imprint": "Impressum",
    "processingContract": "Auftragsverarbeitungsvertrag",
};

