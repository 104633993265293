import React, {useState, useEffect, useRef} from 'react';
import {fabric} from 'fabric';
import {useStore} from '../../state';
import {ChromePicker} from 'react-color';
import deTranslations from "../../translations/de";
import enTranslations from "../../translations/en";
import {AiFillCloseCircle} from "react-icons/ai";
import {IoChevronBackOutline} from "react-icons/io5";

function ImageEditor() {
    const [canvas, setCanvas] = useState(null);
    const [canvasText, setCanvasText] = useState(null);
    const [canvasRect, setCanvasRect] = useState(null);
    const [inputText, setInputText] = useState('');
    const [textColor, setTextColor] = useState('#000000');
    const [rectColor, setRectColor] = useState('#fFfFfF');
    const [showTextColorPicker, setShowTextColorPicker] = useState(false);
    const [showRectColorPicker, setShowRectColorPicker] = useState(false);
    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;

    const canvasRef = useRef(null);
    const pickedImagePath = useStore(state => state.pickedImagePath);

    const increaseStep = useStore(state => state.increaseStep);
    const decreaseStep = useStore(state => state.decreaseStep);

    const MAX_SIZE = 500;

    useEffect(() => {
        if (canvasRef.current) {
            let containerWidth = canvasRef.current.parentElement.clientWidth;
            containerWidth = Math.min(containerWidth, MAX_SIZE);
            const fabricCanvas = new fabric.Canvas(canvasRef.current, {
                width: containerWidth,
                height: containerWidth // Set height to containerWidth for a square canvas
            });
            setCanvas(fabricCanvas);
        }
    }, []);


    useEffect(() => {
        if (canvas && pickedImagePath) {
            fabric.Image.fromURL(pickedImagePath, function (img) {
                const scale = canvas.getWidth() / img.width;
                img.scale(scale);
                img.set({
                    left: canvas.getWidth() / 2,
                    top: canvas.getHeight() / 2,
                    originX: 'center',
                    originY: 'center',
                    selectable: false
                });

                canvas.add(img);
                img.moveTo(0);

                addRectAndText(); // Add rectangle and text to the canvas

                canvas.renderAll();
            });
        }
    }, [canvas, pickedImagePath]);


    useEffect(() => {
        if (canvas && !canvasText) {
            const baseFontSize = 32;
            const scaleFactor = canvas.getWidth() / 500;
            const currentFontSize = baseFontSize * scaleFactor;

            const text = new fabric.Text(inputText, {
                left: canvas.getWidth() / 2,
                top: canvas.getHeight() * 0.8,
                originX: 'center',
                originY: 'center',
                fill: textColor,
                fontFamily: 'Open Sans',
                fontSize: currentFontSize,
                textAlign: 'center',
            });

            text.setCoords(); // Stellen Sie sicher, dass Sie die Koordinaten setzen, bevor Sie Breite und Höhe abrufen

            const rect = new fabric.Rect({
                left: canvas.getWidth() / 2,
                top: canvas.getHeight() * 0.8,
                originX: 'center',
                originY: 'center',
                width: canvas.getWidth() * 0.9,
                height: canvas.getHeight() * 0.3,
                fill: rectColor,
                selectable: false,
            });
            canvas.add(rect);
            setCanvasRect(rect);

            canvas.add(text);
            setCanvasText(text);
        }
    }, [canvas, inputText, canvasText, textColor]);

    const addRectAndText = () => {
        const baseFontSize = 32;
        const scaleFactor = canvas.getWidth() / 500;
        const currentFontSize = baseFontSize * scaleFactor;

        const text = new fabric.Text(inputText, {
            left: canvas.getWidth() / 2,
            top: canvas.getHeight() * 0.8,
            originX: 'center',
            originY: 'center',
            fill: textColor,
            fontFamily: 'Open Sans',
            fontSize: currentFontSize,
            textAlign: 'center',
        });

        const rect = new fabric.Rect({
            left: canvas.getWidth() / 2,
            top: canvas.getHeight() * 0.8,
            originX: 'center',
            originY: 'center',
            width: canvas.getWidth() * 0.9,
            height: canvas.getHeight() * 0.3,
            fill: rectColor,
            selectable: false,
        });

        canvas.add(rect);
        canvas.add(text);

        setCanvasRect(rect);
        setCanvasText(text);
    };

    const toggleTextColorPicker = () => {
        setShowTextColorPicker(!showTextColorPicker);
    };

    const toggleRectColorPicker = () => {
        setShowRectColorPicker(!showRectColorPicker);
    };
    const handleRectColorChange = (color) => {
        setRectColor(color.hex);
        if (canvasRect) {
            canvasRect.set({fill: color.hex});
            canvas.renderAll();
        }
    };

    function formatText(input) {
        const MAX_CHARS_PER_LINE = 24;
        let lines = input.split('\n');
        let formattedText = '';
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].length > MAX_CHARS_PER_LINE) {
                const firstPart = lines[i].slice(0, MAX_CHARS_PER_LINE);
                const secondPart = lines[i].slice(MAX_CHARS_PER_LINE, MAX_CHARS_PER_LINE * 2);
                formattedText += firstPart;
                if (i < lines.length - 1 || secondPart) {
                    formattedText += '\n';
                }
                formattedText += secondPart;
            } else {
                formattedText += lines[i];
            }
            if (i < lines.length - 1) {
                formattedText += '\n';
            }
        }
        return formattedText;
    }

    const handleTextChange = (event) => {
        const input = event.target.value;
        const formatted = formatText(input);
        setInputText(formatted);
        if (canvasText) {
            canvasText.text = formatted;
            canvas.renderAll();
        }
    };

    const handleColorChange = (color) => {
        setTextColor(color.hex);
        if (canvasText) {
            canvasText.set({fill: color.hex});
            canvas.renderAll();
        }
    };

    const saveCanvasAsPNG = () => {
        if (canvas) {
            const pngData = canvas.toDataURL();
            useStore.getState().setCanvasPng(pngData);
        }
    }

    const isButtonEnabled = pickedImagePath && inputText.length >= 5;

    return (
        <>
            <div className="flex flex-col justify-center mt-6">
                <div className={'flex'}>
                    <div className={'flex cursor-pointer'} onClick={() => {
                        decreaseStep();
                    }}>
                        <IoChevronBackOutline size={20} className={'text-3xl mt-1 cursor-pointer'}/>
                        <p>{t.backButton}</p>
                    </div>
                </div>
                <div className={'mt-6 bg-grey px-4 grey pt-6 pb-2 rounded-t-2xl'}>
                    <h2 className={'text-2xl font-bold pb-4'}>2. {t.customizeImage}</h2>
                    <div className={'flex justify-center'}>
                        <canvas ref={canvasRef}/>
                    </div>
                    <div className="flex flex-col pt-8">
                        <h4 className={'font-bold text-center text-xl md:w-1/3 mx-auto mb-4'}>{t.typeInText}</h4>
                        <textarea
                            value={inputText}
                            onChange={handleTextChange}
                            placeholder={t.areaHelpText}
                            className="mb-4 p-4 text-center border rounded w-full md:w-1/3 mx-auto resize-none"
                            maxLength={49}
                        />
                    </div>
                    <div className="flex font-bold text-xl md:w-1/3 mx-auto items-center pt-6">
                        <div className="flex justify-between">
                            <div className="flex justify-between">
                                <div
                                    className="w-6 h-6 cursor-pointer rounded-full"
                                    onClick={toggleTextColorPicker}
                                    style={{backgroundColor: textColor}}
                                ></div>
                                <h4 className="ml-4 cursor-pointer text-sm" onClick={toggleTextColorPicker}>{t.textColorButton}</h4>
                            </div>
                        </div>
                        {showTextColorPicker && (
                            <div className="relative">
                                <AiFillCloseCircle
                                    className="absolute cursor-pointer top-[-5px] right-[-140px] p-2 z-10"
                                    size={48}
                                    onClick={toggleTextColorPicker}
                                />
                                <div className="absolute top-6 left-1/2 transform -translate-x-1/2">
                                    <ChromePicker disableAlpha={true} color={textColor}
                                                  onChangeComplete={handleColorChange}/>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex font-bold text-xl md:w-1/3 mx-auto items-center pt-6">
                        <div
                            className="w-6 h-6 cursor-pointer rounded-full"
                            onClick={toggleRectColorPicker}
                            style={{backgroundColor: rectColor}}
                        ></div>
                        <h4 className="ml-4 cursor-pointer text-sm" onClick={toggleRectColorPicker}>{t.backgroundColorButton}</h4>
                        {showRectColorPicker && (
                            <div className="relative">
                                <AiFillCloseCircle
                                    className="absolute cursor-pointer top-[-5px] right-[-140px] p-2 z-10"
                                    size={48}
                                    onClick={toggleRectColorPicker}/>
                                <div className="absolute top-6 left-1/2 transform -translate-x-1/2">
                                    <ChromePicker disableAlpha={true} color={rectColor}
                                                  onChangeComplete={handleRectColorChange}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={'bg-grey h-6 rounded-b-2xl'}/>
            <div
                className={`flex select-none justify-center w-full md:w-2/5 mx-auto py-4 ${isButtonEnabled ? 'bg-flame cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} mt-6 mb-12 font-bold text-xl rounded-xl`}
                onClick={() => {
                    if (isButtonEnabled) {
                        saveCanvasAsPNG();
                        increaseStep();
                    }
                }}>
                <p>{t.continueButton}</p>
            </div>
        </>
    );
}

export default ImageEditor;
