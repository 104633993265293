const products = [
    {
        name: "Basic",
        price: "1.990,00",
        priceNumeric: 1990.00,
        description: "Facebook + Instagram",
        reducedPrice: "1.592,00",
        reducedPriceNumeric: 1592.00,
    },
    {
        name: "Premium",
        price: "4.990,00",
        priceNumeric: 4990.00,
        description: "Facebook + Instagram + LinkedIn",
        reducedPrice: "3.992,00",
        reducedPriceNumeric: 3992.00,
    },
]

export default products;