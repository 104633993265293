import {create} from "zustand";

export const useStore = create((set) => ({
    // global
    language: 'de',
    setLanguage: (language) => set({language: language}),
    // base create
    step: 0,
    increaseStep: () => set(state => ({step: state.step + 1})),
    decreaseStep: () => set(state => ({step: state.step - 1})),
    // 1
    pickedImagePath: null,
    setPickedImagePath: (path) => set({pickedImagePath: path}),
    // 2
    canvasPng: null,
    setCanvasPng: (pngData) => set({ canvasPng: pngData }),
    // 3
    description: '',
    setDescription: (description) => set({description: description}),
    targetGroupDescription: '',
    setTargetGroupDescription: (targetGroupDescription) => set({targetGroupDescription: targetGroupDescription}),
    landingPage: '',
    setLandingPage: (landingPage) => set({landingPage: landingPage}),
    // 4
    option: null,
    setOption: (option) => set({option: option}),
    // checkout
    firstname: '',
    setFirstname: (firstname) => set({firstname: firstname}),
    lastname: '',
    setLastname: (lastname) => set({lastname: lastname}),
    email: '',
    setEmail: (email) => set({email: email}),
    phone: '',
    setPhone: (phone) => set({phone: phone}),
    street: '',
    setStreet: (street) => set({street: street}),
    streetNumber: '',
    setStreetNumber: (streetNumber) => set({streetNumber: streetNumber}),
    zip: '',
    setZip: (zip) => set({zip: zip}),
    city: '',
    setCity: (city) => set({city: city}),
    company: '',
    setCompany: (company) => set({company: company}),

    // clear
    clearCanvasState: () => {
        set({
            pickedImagePath: null,
            title: '',
            titleColor: '#000000',
            description: '',
            descriptionColor: '#000000',
            option: '',
        })
    }
}));