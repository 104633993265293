import React from "react";
import {useStore} from "../../state";
import deTranslations from "../../translations/de";
import enTranslations from "../../translations/en";
import {IoChevronBackOutline} from "react-icons/io5";


export default function Overview() {
    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;

    const increaseStep = useStore(state => state.increaseStep);
    const decreaseStep = useStore(state => state.decreaseStep);

    const canvasPng = useStore(state => state.canvasPng);

    const description = useStore(state => state.description);
    const targetGroupDescription = useStore(state => state.targetGroupDescription);
    const landingPage = useStore(state => state.landingPage);

    const option = useStore(state => state.option);


    return (
        <>
            <div className={'flex mt-6'}>
                <div className={'flex cursor-pointer'} onClick={() => {
                    decreaseStep();
                }}>
                    <IoChevronBackOutline size={20} className={'text-3xl mt-1 cursor-pointer'}/>
                    <p>{t.backButton}</p>
                </div>
            </div>
            <div className={'mt-2 bg-grey px-4 pt-6 pb-2 rounded-t-2xl'}>
                <h2 className={'text-2xl font-bold'}>5. {t.overview}</h2>
            </div>
            <div className={'flex flex-col md:flex-row md:justify-around p-6 bg-grey'}>
                <div>
                    <p className={'text-xl font-bold mb-2'}>{t.yourCampaign}:</p>
                    <img src={canvasPng} alt={'canvasPng'} className={'aspect-square w-full sm:w-64'}/>
                </div>
                <div className={''}>
                    <div>
                        <p className={'font-bold mb-2 mt-8'}>{t.description}:</p>
                        <p>{description}</p>
                        <p className={'font-bold mb-2 mt-4'}>{t.overviewTargetGroup}:</p>
                        <p>{targetGroupDescription}</p>
                        <p className={'font-bold mb-2 mt-4'}>Landingpage:</p>
                        <p>{landingPage}</p>
                    </div>
                    <div className={'border-t border-black mt-6'}>
                        <p className={'text-xl font-bold mb-1 mt-4'}>{t.chosenOption}:</p>
                        <p className={'font-bold'}>{option.name}</p>
                        <p>{option.description}</p>
                        <p>{t.overviewTime}: {t.timeOption}</p>
                    </div>
                    <div
                        className={'flex justify-center pt-6 text-2xl font-bold border-t border-black border-dashed mt-4'}>
                        <p className={''}>{option.price}€</p>
                    </div>
                </div>
            </div>
            <div className={'bg-grey h-6 rounded-b-2xl'}/>
            <div
                className={`flex select-none justify-center w-full md:w-2/5 mx-auto py-4 ${option ? 'bg-flame cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} mt-6 mb-12 font-bold text-xl rounded-xl`}
                onClick={option ? increaseStep : null}>
                <p>{t.continueButton}</p>
            </div>
        </>
    );
}