import React from 'react';
import {Helmet} from "react-helmet";
import {useStore} from "../state";
import deTranslations from "../translations/de";
import enTranslations from "../translations/en";

import Header from "../components/base/Header";
import Footer from "../components/base/Footer";
import ImageEditor from "../components/create/ImageEditor";
import ImagePicker from "../components/create/ImagePicker";
import AdditionalInformations from "../components/create/AdditionalInformations";
import PickOption from "../components/create/PickOption";
import Overview from "../components/create/Overview";
import CheckOut from "../components/create/CheckOut";

export default function CreatePostFlow() {
    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;

    const step = useStore(state => state.step);

    const renderStepContent = () => {
        switch (step) {
            case 0:
                return <ImagePicker/>;
            case 1:
                return <ImageEditor/>;
            case 2:
                return <AdditionalInformations/>;
            case 3:
                return <PickOption/>;
            case 4:
                return <Overview/>;
            case 5:
                return <CheckOut/>;
            default:
                return null;
        }
    };

    return (
        <div className={'page bg-white '}>
            <Helmet>
                <title>{t.pageTitle}</title>
                <meta name="description" content={t.pageDescription}/>
            </Helmet>
            <Header/>
            <div className={'body flex flex-col justify-center px-6 text-black md:w-3/4 lg:w-1/2 mx-auto'}>
                {renderStepContent()}
            </div>
            <Footer/>
        </div>
    );
}
