import React, {useState} from "react";
import {useStore} from "../../state";
import deTranslations from "../../translations/de";
import enTranslations from "../../translations/en";
import {IoChevronBackOutline} from "react-icons/io5";


export default function CheckOut() {
    const [promoCode, setPromoCode] = useState("")
    const [isPromoCodeValid, setIsPromoCodeValid] = useState(false)

    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;

    const decreaseStep = useStore(state => state.decreaseStep);

    const option = useStore(state => state.option);
    const canvasPng = useStore(state => state.canvasPng);

    const firstname = useStore(state => state.firstname);
    const lastname = useStore(state => state.lastname);
    const email = useStore(state => state.email);
    const phone = useStore(state => state.phone);
    const company = useStore(state => state.company);
    const street = useStore(state => state.street);
    const streetNumber = useStore(state => state.streetNumber);
    const zip = useStore(state => state.zip);
    const city = useStore(state => state.city);

    const setFirstname = useStore(state => state.setFirstname);
    const setLastname = useStore(state => state.setLastname);
    const setEmail = useStore(state => state.setEmail);
    const setPhone = useStore(state => state.setPhone);
    const setCompany = useStore(state => state.setCompany);
    const setStreet = useStore(state => state.setStreet);
    const setStreetNumber = useStore(state => state.setStreetNumber);
    const setZip = useStore(state => state.setZip);
    const setCity = useStore(state => state.setCity);

    const [error, setError] = useState("")


    function handlePromoCodeCheck(value) {
        if (value === "XdeGUT23X") {
            setError("")
            setPromoCode("")
            setIsPromoCodeValid(true)
        } else {
            setError("not valid promo code")
        }
    }

    function allFieldsAreFilled() {
        const requiredFields = [firstname, lastname, email, phone, street, streetNumber, zip, city];
        return requiredFields.every(field => field && field.trim() !== "");
    }

    return (
        <>
            <div className={'flex mt-6'}>
                <div className={'flex cursor-pointer'} onClick={decreaseStep}>
                    <IoChevronBackOutline size={20} className={'text-3xl mt-1 cursor-pointer'}/>
                    <p>{t.backButton}</p>
                </div>
            </div>
            <div className={'mt-2 bg-grey px-4 pt-6 pb-2 rounded-t-2xl'}>
                <h2 className={'text-2xl font-bold'}>6. {t.checkOut}</h2>
            </div>
            <div className={'flex flex-col p-6 bg-grey'}>

                <div className="mb-4">
                    <h3 className="text-xl font-bold mb-2">{t.total}: {isPromoCodeValid ? option.reducedPrice : option.price}€</h3>
                    <div className="flex items-center space-x-3">
                        <input
                            type="text"
                            placeholder="Promo-Code"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            className="w-2/3 p-2 bg-grey border-2 border-black rounded-xl"
                        />
                        <button
                            onClick={() => handlePromoCodeCheck(promoCode)}
                            className="bg-flame text-white py-2 px-4 rounded-xl"
                        >
                            {t.checkPromoCode}
                        </button>
                    </div>
                    <p className={'text-center mt-2 text-red-600'}>{error}</p>
                </div>
                {renderInputField(t.checkFirstname, firstname, setFirstname)}
                {renderInputField(t.checkLastname, lastname, setLastname)}
                {renderInputField(t.checkEmail, email, setEmail)}
                {renderInputField(t.checkPhone, phone, setPhone)}
                {renderInputField(t.checkCompany + " (optional)", company, setCompany)}
                {renderInputField(t.checkStreet, street, setStreet)}
                {renderInputField(t.checkStreetNumber, streetNumber, setStreetNumber)}
                {renderInputField(t.checkZip, zip, setZip)}
                {renderInputField(t.checkCity, city, setCity)}
                <div className="flex space-x-3 items-center mb-4">
                    <input type="checkbox" className="mr-2"/>
                    <label>
                        Ich habe die <span className="underline cursor-pointer">Datenschutzerklärung</span> gelesen und
                        akzeptiere sie.
                    </label>
                </div>

                <div className="flex items-center space-x-3 mb-4">
                    <input type="checkbox" className="mr-2"/>
                    <label>
                        Ich stimme der Ausführung des Vertrages vor Ablauf der Widerrufsfrist ausdrücklich zu.
                    </label>
                </div>
            </div>
            <div className={'bg-grey h-6 rounded-b-2xl'}/>
            <div
                className={`flex select-none justify-center w-full md:w-2/5 mx-auto py-4 ${allFieldsAreFilled() ? 'bg-flame cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} mt-6 mb-12 font-bold text-xl rounded-xl`}
                onClick={async () => {
                    if (allFieldsAreFilled()) {
                        let json = {
                            firstname: firstname,
                            lastname: lastname,
                            email: email,
                            phone: phone,
                            company: company,
                            street: street,
                            streetNumber: streetNumber,
                            zip: zip,
                            city: city,
                            option: option,
                            price: isPromoCodeValid ? option.reducedPriceNumeric : option.priceNumeric,
                            canvasPng: canvasPng
                        }

                        // Den Firebase Functions-Endpoint aufrufen
                        try {
                            const response = await fetch('https://us-central1-gruenderapp-2941d.cloudfunctions.net/createPaymentValidyt', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(json)
                            });

                            const responseData = await response.json();
                            if (response.ok) {
                                console.log("Daten erfolgreich gesendet:", responseData);
                            } else {
                                console.error("Fehler beim Senden der Daten:", responseData.error);
                            }
                        } catch (error) {
                            console.error("Netzwerkfehler:", error);
                        }
                    }
                }}>
                <p>{t.bookNow}</p>
            </div>
        </>
    );

    function renderInputField(label, value, setter) {
        return (
            <div className='mb-4'>
                <p className={'font-bold'}>{label}</p>
                <input
                    value={value}
                    className={'w-full h-12 md:h-16 bg-grey border-2 border-black rounded-xl p-4'}
                    placeholder={label}
                    onChange={(e) => setter(e.target.value)}
                />
            </div>
        );
    }

}