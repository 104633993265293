import ImageItem from "./ImageItem";
import React from "react";
import {useStore} from "../../state";
import deTranslations from "../../translations/de";
import enTranslations from "../../translations/en";
import {IoChevronBackOutline} from "react-icons/io5";


export default function AdditionalInformations() {
    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;

    const increaseStep = useStore(state => state.increaseStep);
    const decreaseStep = useStore(state => state.decreaseStep);
    const setDescription = useStore(state => state.setDescription);
    const setTargetGroupDescription = useStore(state => state.setTargetGroupDescription);
    const setLandingPage = useStore(state => state.setLandingPage);

    const description = useStore(state => state.description);
    const targetGroupDescription = useStore(state => state.targetGroupDescription);
    const landingPage = useStore(state => state.landingPage);

    const allFields = description.length > 10 && targetGroupDescription.length > 10 && landingPage;

    return (
        <>
            <div className={'flex mt-6'}>
                <div className={'flex cursor-pointer'} onClick={() => {
                    decreaseStep();
                }}>
                    <IoChevronBackOutline size={20} className={'text-3xl mt-1 cursor-pointer'}/>
                    <p>{t.backButton}</p>
                </div>
            </div>
            <div className={'mt-2 bg-grey px-4 pt-6 pb-2 rounded-t-2xl'}>
                <h2 className={'text-2xl font-bold'}>3. {t.additionalInfos}</h2>
            </div>
            <div className={'flex flex-col overflow-y-scroll scroll-y-auto p-6 bg-grey'}>
                <h2 className={'text-xl font-bold mb-3'}>{t.descriptionOfCampaign}</h2>
                <textarea
                    value={description}
                    className={'w-full h-32 md:h-64 bg-white text-black border-2 border-black rounded-xl p-4'}
                    placeholder={t.descriptionPlaceholder}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <h2 className={'text-xl font-bold mt-8 mb-3'}>{t.targetGroupDescription}</h2>
                <textarea
                    value={targetGroupDescription}
                    className={'w-full h-32 md:h-64 bg-grey border-2 border-black rounded-xl p-4'}
                    placeholder={t.targetGroupDescriptionPlaceholder}
                    onChange={(e) => setTargetGroupDescription(e.target.value)}
                />

                <h2 className={'text-xl font-bold mt-8 mb-3'}>{t.landingPageURL}</h2>
                <input
                    value={landingPage}
                    className={'w-full h-12 md:h-16 bg-grey border-2 border-black rounded-xl p-4'}
                    placeholder={'https://example.com'}
                    onChange={(e) => setLandingPage(e.target.value)}
                />
            </div>
            <div className={'bg-grey h-6 rounded-b-2xl'}/>
            <div
                className={`flex select-none justify-center w-full md:w-2/5 mx-auto py-4 ${allFields ? 'bg-flame cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} mt-6 mb-12 font-bold text-xl rounded-xl`}
                onClick={allFields ? increaseStep : null}>
                <p>{t.continueButton}</p>
            </div>
        </>
    );
}