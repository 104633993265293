import React from "react";


export default function ImageItem ({src, alt, isSelected = false, onClick}) {
    return(
        <div
            onClick={onClick}
            className={isSelected ? 'border-flame border-4 cursor-pointer' : 'cursor-pointer'}>
            <img alt={alt} src={src}/>
        </div>
    );
}
