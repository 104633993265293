export default {
    "total": "Total",
    "checkPromoCode": "Redeem",
    "checkFirstname":"Firstname",
    "checkLastname":"Lastname",
    "checkEmail":"E-Mail",
    "checkPhone":"Phone",
    "checkCompany":"Company",
    "checkStreet":"Street",
    "checkStreetNumber":"Street number",
    "checkZip":"Zip",
    "checkCity":"City",

    "bookNow": "Book Now",
    "checkOut": "Book your campaign",
    "chosenOption": "Chosen Option",
    "overviewTime": "Duration",
    "overviewTargetGroup": "Target group description",
    "yourCampaign": "Your Campaign",
    "overview": "Overview",
    "timeOption": "3 Weeks",
    "pickOption": "Pick an option",
    "landingPageURL": "Enter the URL of your landing page",
    "targetGroupDescription": "Description of your target group",
    "targetGroupDescriptionPlaceholder": "Target Group",
    "descriptionOfCampaign": "Describe your campaign. This text will be displayed below your post.",
    "descriptionPlaceholder": "Description for your campaign",
    "description": "Description",
    "additionalInfos": "Additional Information",
    "areaHelpText": "Your text",
    "typeInText":"Type in your text",
    "backButton": "return",
    "customizeImage": "Customize Image",
    "textColorButton": "text color",
    "backgroundColorButton": "background color",
    "continueButton": "Continue",
    "pickAnImage": "Pick an image",
    "pageDescription": "The easiest way to test your business idea and gain initial leads.",
    "withoutPrototype": "Possible even without a prototype",
    "reachThousands": "Reach thousands of prospects without own social media presence",
    "saveTimeResources": "Save time and resources",
    "heroText": "The easiest way to test your business idea and gain initial leads.",
    "contact": "Contact",
    "dataProcessingContract": "Order Processing Agreement",
    "aboutUs": "About Us",
    "legalNotice": "Imprint",
    "privacy": "Privacy",
    "copyright": "© 2023 validyt",
    "forFounders": "For Founders",
    "forCompanies": "For Companies",
    "startNow": "Start Now",
    "imprint": "Imprint",
    "processingContract": "Order Processing Contract",
    "pageTitle": "validyt.",
    "campaignStart": "Start Campaign Now",
    "beforeInvestingTitle": "Before investing tens of thousands of Euros and countless hours of work into a new business idea, easily and cost-effectively test your idea with validyt.",
    "createCampaignTitle": "Create Campaign",
    "createCampaignDescription": "Create your social media campaign in just a few minutes. Define your target audience and decide which channels you want to advertise on.",
    "altSocialMedias": "Variety of social media platforms",
    "withoutValidytDescription": "For a successful campaign without validyt, you'd need a professional profile with content and followers to be taken seriously. With validyt, you use our advertising profile and don't need to create your own.",
    "altProfile": "Profile picture",
    "collectLeadsTitle": "Collect Leads",
    "collectLeadsDescription": "Based on the figures, you can decide whether to implement the business idea. The key figures provide you with valuable information for future campaigns.",
    "peopleReached": "People Reached",
    "clicks": "Clicks",
    "leads": "Leads",
};

