import React, {useState, useEffect, useRef} from 'react';
import {useStore} from "../../state";
import {FaLanguage, FaBars} from 'react-icons/fa';
import {useSpring, animated} from 'react-spring';
import {AiOutlineClose} from "react-icons/ai";
import deTranslations from "../../translations/de";  // Importieren Sie die Übersetzungsdateien
import enTranslations from "../../translations/en";
import {Link} from "react-router-dom";

export default function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const setLanguage = useStore(state => state.setLanguage);
    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;  // Dieses t-Objekt hält Ihre Übersetzungen
    const navRef = useRef(null);

    const navAnimation = useSpring({
        transform: isNavOpen ? 'translate3d(0,0,0)' : 'translate3d(100%,0,0)'
    });

    const toggleLanguage = () => {
        if (language === 'de') {
            setLanguage('en');
        } else {
            setLanguage('de');
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setIsNavOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={'bg-flame p-5 flex justify-between items-center'}>
            <h1 className={'text-3xl font-black text-black'}>validyt.</h1>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center font-bold text-xl text-black">
                {/*<Link to={'/founders'} className="cursor-pointer pr-6">{t.forFounders}</Link>*/}
                {/*<Link to={'/companies'} className="cursor-pointer border-l px-6 border-black">{t.forCompanies}</Link>*/}
                <Link to={'/about'} className="cursor-pointer px-6">{t.aboutUs}</Link>
                <Link to={'/contact'} className="cursor-pointer border-l px-6 border-black">{t.contact}</Link>
                <Link to={'/create'} className="cursor-pointer bg-white px-4 py-3 rounded-xl">{t.startNow}</Link>
                <div onClick={toggleLanguage} className="flex cursor-pointer items-center px-6">
                    <FaLanguage size={30}/>
                    <span className="select-none ml-2">{language === 'de' ? 'EN' : 'DE'}</span>
                </div>
            </div>

            {/* Mobile Navigation */}
            <div className="md:hidden flex items-center text-black">
                <button onClick={() => setIsNavOpen(!isNavOpen)}>
                    <FaBars size={28}/>
                </button>
            </div>

            {/* Slide Nav (Side Navigation) */}
            <animated.div ref={navRef} style={navAnimation}
                          className="fixed flex flex-col top-0 right-0 h-full w-64 bg-white z-50 p-5 text-black">
                <div className={'flex justify-end'} onClick={() => setIsNavOpen(false)}>
                    <AiOutlineClose size={'20'}/>
                </div>
                <div className="flex flex-col space-y-2 mt-5 font-bold text-2xl">
                    {/*<Link to={'/founders'}>{t.forFounders}</Link>*/}
                    {/*<Link to={'/companies'}>{t.forCompanies}</Link>*/}
                    <Link to={'/about'}>{t.aboutUs}</Link>
                    <Link to={'/contact'}>{t.contact}</Link>
                </div>
                <Link to={'/create'}
                      className="text-center bg-flame px-4 mt-10 text-2xl py-3 rounded-xl font-bold ">{t.startNow}</Link>
                <div className={'font-bold flex mt-16 flex-col justify-center text-center space-y-1 underline'}>
                    <Link to={'/imprint'}>{t.imprint}</Link>
                    <Link to={'/privacy'}>{t.privacy}</Link>
                    <Link to={'/processing-contract'}>{t.processingContract}</Link>
                </div>
                <div onClick={toggleLanguage} className="flex justify-center cursor-pointer items-center mt-12">
                    <FaLanguage size={30}/>
                    <span className="select-none ml-2">{language === 'de' ? 'EN' : 'DE'}</span>
                </div>
            </animated.div>
        </div>
    );
}
