import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";
import ProcessingContract from "./pages/ProcessingContract";
import CreatePostFlow from "./pages/CreatePostFlow";
import MaintainMe from "./pages/MaintainMe";

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <MaintainMe/>,
        },
        {
            path: "/about",
            element: <About/>,
        },
        {
            path: "/contact",
            element: <Contact/>,
        },
        {
            path: "/imprint",
            element: <Imprint/>,
        },
        {
            path: "/privacy",
            element: <Privacy/>,
        },
        {
            path: "/processing-contract",
            element: <ProcessingContract/>,
        },
        {
            path: "/create",
            element: <CreatePostFlow/>,
        }
    ]);

    return (
        <div className="App">
            <RouterProvider router={router}/>
        </div>
    );
}

export default App;
