import {useStore} from "../../state";
import deTranslations from "../../translations/de";
import enTranslations from "../../translations/en";
import {Link} from "react-router-dom";

export default function Footer() {
    const language = useStore(state => state.language);
    const t = language === 'de' ? deTranslations : enTranslations;

    return (
        <div className={'flex flex-col bg-flame px-6 text-black text-sm pt-12'}>
            <div className={'flex flex-col space-y-2 underline font-semibold md:w-3/4 md:mx-auto'}>
                <Link to={'/contact'}><p className={'cursor-pointer'}>{t.contact}</p></Link>
                <Link to={'/processing-contract'}><p className={'cursor-pointer'}>{t.dataProcessingContract}</p></Link>
                <Link to={'/about'}><p className={'cursor-pointer'}>{t.aboutUs}</p></Link>
                <Link to={'/imprint'}><p className={'cursor-pointer'}>{t.legalNotice}</p></Link>
                <Link to={'/privacy'}><p className={'cursor-pointer'}>{t.privacy}</p></Link>
            </div>
            <p className={'text-center font-bold text-xs mb-3 mt-6'}>{t.copyright}</p>
        </div>
    );
}
